var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "post-item-container mb-4 cursor-pointer" }, [
    _c("div", { staticClass: "post-content-container m-0 d-flex" }, [
      _c("div", { staticClass: "position-relative" }, [
        _c(
          "div",
          { staticClass: "position-relative" },
          [
            _c("vue-skeleton-loader", {
              staticClass: "post-img box-shadow",
              attrs: {
                width: "100",
                height: "100",
                animation: "fade",
                rounded: true,
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "w-100 position-relative" }, [
        _c(
          "div",
          {
            staticClass:
              "post-content d-flex flex-column justify-content-between h-100",
          },
          [
            _c(
              "div",
              {
                staticClass: "post-content-custom",
                class: [
                  {
                    "d-flex flex-column justify-content-between h-100 mb-2":
                      _vm.$device.isMobile,
                  },
                ],
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "post-title mt-1 d-flex justify-content-between",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "post-title" },
                      [
                        _c("vue-skeleton-loader", {
                          staticClass: "post-title",
                          attrs: {
                            width: "100",
                            height: "100",
                            animation: "fade",
                            rounded: true,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "top-infor my-2" }, [
                  _c(
                    "span",
                    { staticClass: "author-name" },
                    [
                      _c("vue-skeleton-loader", {
                        staticClass: "author-name",
                        attrs: {
                          width: "100",
                          height: "100",
                          animation: "fade",
                          rounded: true,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "post-summary" },
                  [
                    _c("vue-skeleton-loader", {
                      staticClass: "post-summary",
                      attrs: {
                        width: "100",
                        height: "100",
                        animation: "fade",
                        rounded: true,
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c("div", [
              _c("div", { staticClass: "d-flex justify-content-between" }, [
                _c(
                  "div",
                  { staticClass: "hashtag" },
                  [
                    _c("vue-skeleton-loader", {
                      staticClass: "hashtag",
                      attrs: {
                        animation: "fade",
                        width: "100",
                        height: "100",
                        rounded: true,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }