
import Common from "~/mixins/CommonMixins.vue";
import { Component, Prop, mixins } from "nuxt-property-decorator";
import { Post, PostCategory } from "~/modals";
import { plainToClass } from "class-transformer";
import VueSkeletonLoader from "skeleton-loader-vue";
import IcMoveRight from "~/assets/icons/IcMoveRight.vue";
import HotNews from "./HotNews.vue";
import { News } from "~/modals";

@Component({
  components: {
    "skeleton-loader-vue": VueSkeletonLoader,
    IcMoveRight,
    HotNews,
  },
})
export default class PostsRight extends mixins<Common>(Common) {
  @Prop({ type: Function, required: true, default: () => {} }) getCategoryName;
  @Prop({type: Boolean, default: true }) isTabAll;

  popularHashtags: any = [];
  topArticles: Post[] = [];
  listHotNews: News[] = [];
  textContent = "";
  textContent1 = "";


  async created() {
    try {
      const rs = await Promise.all([
        this.$axios.$get(
          `/api/v1/hashtags/top?language_code=${
            this.$auth.loggedIn
              ? this.$auth.user.support_language.code
              : this.$i18n.locale
          }`
        ),
        this.$axios.$get(
          `/api/v1/blogs/tops?page=1&paging=5${
            this.$auth.loggedIn ? "" : "&language_code=" + this.$i18n.locale
          }`
        ),
        this.$axios.$get(
          `/api/v1/news/hots?page=1&paging=8&language_code=${this.$i18n.locale}`
        ),
      ]);
      this.popularHashtags = rs[0]["data"];
      this.topArticles = plainToClass(Post, rs[1]["data"] as []);
      this.listHotNews = plainToClass(News, rs[2]["data"] as []);
    } catch (error) {}
  }

  get filteredHashtag() {
    if (!this.popularHashtags) {
      return;
    }
    return this.filteredArrayLanguageCode(
      this.cleanSource(this.popularHashtags),
      this.$i18n.locale
    );
  }
  gotoSearchHashtag(hashtag) {
    return (this as any).localePath({
      name: "posts-search",
      query: { hashtag_ids: hashtag.id, hashtag_name: hashtag.name },
    });
  }
  onGoToPostDetail(post) {
    return (this as any).localePath({
      name: "posts-slug",
      params: {
        slug: this.$formatSlugUrl(
          post.title || "",
          this.$fromUUID(post.id || "")
        ),
      },
    });
  }
}
