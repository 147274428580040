var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "position-sticky col-wrapper" },
    [
      _vm.isTabAll
        ? _c("div", { staticClass: "hashtag pl-3 pl-0 pt-4 pb-3" }, [
            _c("div", { staticClass: "pb-4" }, [
              _c("span", { staticClass: "hashtag-title" }, [
                _vm._v(_vm._s(_vm.$t("forums.popular_hashtag"))),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "hashtag-content d-flex" },
              [
                !_vm.filteredHashtag.length
                  ? _vm._l(3, function (n) {
                      return _c(
                        "div",
                        { key: n, staticClass: "hashtag-skeletons-wrapper" },
                        [
                          _c("skeleton-loader-vue", {
                            staticClass: "rounded-15",
                            attrs: {
                              width: "100px",
                              height: "29px",
                              animation: "fade",
                              rounded: true,
                            },
                          }),
                          _vm._v(" "),
                          _c("skeleton-loader-vue", {
                            staticClass: "rounded-15",
                            attrs: {
                              width: "68px",
                              height: "29px",
                              animation: "fade",
                              rounded: true,
                            },
                          }),
                          _vm._v(" "),
                          _c("skeleton-loader-vue", {
                            staticClass: "rounded-15",
                            attrs: {
                              width: "156px",
                              height: "29px",
                              animation: "fade",
                              rounded: true,
                            },
                          }),
                        ],
                        1
                      )
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(_vm.filteredHashtag, function (hashtag, index) {
                  return _c(
                    "nuxt-link",
                    {
                      directives: [
                        {
                          name: "tippy",
                          rawName: "v-tippy",
                          value: {
                            delay: [1000, 200],
                            placement: "bottom-start",
                            animateFill: false,
                            animation: "scale",
                            zIndex: _vm.textContent === "" ? -1 : 9999,
                          },
                          expression:
                            "{\n          delay: [1000, 200],\n          placement: 'bottom-start',\n          animateFill: false,\n          animation: 'scale',\n          zIndex: textContent === '' ? -1 : 9999,\n        }",
                        },
                      ],
                      key: index,
                      staticClass: "hashtag-name cursor-pointer",
                      attrs: {
                        to: _vm.gotoSearchHashtag(hashtag),
                        id: `most-hashtag-${hashtag.id}`,
                        content: `<span style='word-break: break-word;'>
          ${hashtag.name}
        </span>`,
                      },
                      nativeOn: {
                        contextmenu: function ($event) {
                          return (() => {
                            _vm.textContent = ""
                          }).apply(null, arguments)
                        },
                        mouseover: function ($event) {
                          return (() => {
                            _vm.textContent = _vm.$handleHover(
                              `most-hashtag-${hashtag.id}`,
                              "#" + hashtag.name,
                              1,
                              15,
                              17,
                              null
                            )
                          }).apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " + _vm._s("#" + hashtag.name) + "\n      "
                      ),
                    ]
                  )
                }),
              ],
              2
            ),
          ])
        : [
            _c(
              "div",
              { staticClass: "hashtag" },
              [_c("HotNews", { attrs: { hotNews: _vm.listHotNews } })],
              1
            ),
          ],
      _vm._v(" "),
      _vm.topArticles.length > 0
        ? _c("div", { staticClass: "top-article p-3 block-box" }, [
            _c("div", { staticClass: "d-flex w-100 justify-content-between" }, [
              _c("span", { staticClass: "top-article-label" }, [
                _vm._v(_vm._s(_vm.$t("news.most_viewer"))),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-flex button-create-post" },
                [
                  _c("span", [_vm._v(_vm._s(_vm.$t("common.see_more")))]),
                  _vm._v(" "),
                  _c("IcMoveRight"),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mt-3" },
              _vm._l(_vm.topArticles, function (article) {
                return _c(
                  "div",
                  { key: article.id, staticClass: "top-article-item" },
                  [
                    _c(
                      "nuxt-link",
                      { attrs: { to: _vm.onGoToPostDetail(article) } },
                      [
                        _c("nuxt-img", {
                          staticClass: "cursor-pointer img",
                          attrs: {
                            width: "62",
                            height: "62",
                            format: "webp",
                            src: article.thumbnailUrl,
                            alt: "",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "nuxt-link",
                      {
                        staticClass: "top-article-item__content",
                        attrs: { to: _vm.onGoToPostDetail(article) },
                      },
                      [
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.$isIE,
                                expression: "!$isIE",
                              },
                              {
                                name: "tippy",
                                rawName: "v-tippy",
                                value: {
                                  delay: [1000, 200],
                                  placement: "bottom-start",
                                  animateFill: false,
                                  animation: "scale",
                                  zIndex: _vm.textContent === "" ? -1 : 9999,
                                },
                                expression:
                                  "{\n              delay: [1000, 200],\n              placement: 'bottom-start',\n              animateFill: false,\n              animation: 'scale',\n              zIndex: textContent === '' ? -1 : 9999,\n            }",
                              },
                            ],
                            staticClass: "top_article_title mb-1",
                            attrs: {
                              id: "top-article-" + article.id,
                              content: `<span style='word-break: break-word; white-space: pre-line;'>${article.title}</span>`,
                            },
                            on: {
                              contextmenu: function ($event) {
                                return (() => {
                                  _vm.textContent = ""
                                }).apply(null, arguments)
                              },
                              mouseover: () => {
                                _vm.textContent = _vm.$handleHover(
                                  `top-article-${article.id}`,
                                  article.title,
                                  2,
                                  22,
                                  0,
                                  null
                                )
                              },
                            },
                          },
                          [_vm._v(_vm._s(article.title))]
                        ),
                        _vm._v(" "),
                        _c(
                          "client-only",
                          [
                            _c(
                              "v-clamp",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.$isIE,
                                    expression: "$isIE",
                                  },
                                ],
                                staticClass: "top_article_title mb-1",
                                attrs: {
                                  autoresize: "",
                                  "max-lines": 2,
                                  tag: "p",
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(article.title) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tippy",
                                rawName: "v-tippy",
                                value: {
                                  delay: [1000, 200],
                                  placement: "bottom-start",
                                  animateFill: false,
                                  animation: "scale",
                                  zIndex: _vm.textContent ? -1 : 9999,
                                },
                                expression:
                                  "{\n              delay: [1000, 200],\n              placement: 'bottom-start',\n              animateFill: false,\n              animation: 'scale',\n              zIndex: textContent ? -1 : 9999,\n            }",
                              },
                            ],
                            staticClass: "text-date",
                            attrs: {
                              id: "time-new-posts-" + article.id,
                              content: `<span style='word-break: break-word;white-space:pre-line;word-wrap: break-word; display: block;'>${_vm.formatDateTimeCommon(
                                article.publishedAt
                              )}</span>`,
                            },
                            on: {
                              contextmenu: function ($event) {
                                return (() => {
                                  _vm.textContent = ""
                                }).apply(null, arguments)
                              },
                              mouseover: () => {
                                _vm.textContent = _vm.$handleHover(
                                  `time-new-posts-${article.id}`,
                                  article.title,
                                  2,
                                  22,
                                  0,
                                  null
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.displayCreateTime(article.publishedAt)
                                ) +
                                "\n          "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              0
            ),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }