
import { Component, Vue } from "nuxt-property-decorator";
import VueSkeletonLoader from 'skeleton-loader-vue';
@Component({
  components: { 
    VueSkeletonLoader
  }
})
export default class SkeletonPostListItem extends Vue {
}
