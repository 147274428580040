
import { Vue, Component, Prop } from "nuxt-property-decorator";
import { News } from "~/modals";
import IcMoveRight from "~/assets/icons/IcMoveRight.vue";

@Component({
  components: {
    HotNewsItem: () => import("~/components/news/HotNewsItem.vue"),
    SkeletonHotNews: () => import("~/components/news/SkeletonHotNews.vue"),
    IcMoveRight,
  },
})
export default class HotNews extends Vue {
  @Prop({ type: Array, required: false, default: "" }) hotNews!: News[];
  @Prop({ type: Number, required: false, default: "" }) hotNewsCnt!: number;
  @Prop({ type: String, required: false, default: "" }) currentTab!: string;
  @Prop({ type: Number, required: false, default: 4 }) minShowMore!: number;
  @Prop({ type: Boolean, required: false, default: false }) isLoading;

  get getHotNews() {
    return this.currentTab == "ALL"
      ? this.hotNews.slice(0, 4)
      : this.hotNews.slice(0, 8);
  }

  onGotoCategory(id) {
    this.$emit("onGotoCategory", id);
  }

  goHotNews() {
    return (this as any).localePath({
      name: "news",
      hash: "#HOT",
    });
  }
}
