var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.isLoading,
          expression: "!isLoading",
        },
      ],
      staticClass: "hot-news",
    },
    [
      _c(
        "div",
        { staticClass: "hot-news-top d-flex justify-content-between" },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isLoading,
                  expression: "!isLoading",
                },
              ],
              staticClass: "hot-news-title",
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("news.hot_news")) + "\n    ")]
          ),
          _vm._v(" "),
          _c(
            "nuxt-link",
            { staticClass: "see-more", attrs: { to: _vm.goHotNews() } },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("common.see_more")) + "\n      "
              ),
              _c("IcMoveRight"),
            ],
            1
          ),
          _vm._v(" "),
          _c("skeleton-hot-news", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isLoading,
                expression: "isLoading",
              },
            ],
            staticClass: "mb-2 col-md-12",
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isLoading,
              expression: "!isLoading",
            },
          ],
        },
        _vm._l(_vm.getHotNews, function (news) {
          return _c("div", { staticClass: "news-item" }, [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: news.hot,
                    expression: "news.hot",
                  },
                ],
                staticClass: "hot",
                staticStyle: { color: "#eb5757" },
              },
              [_vm._v("Hot | ")]
            ),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(news.title))]),
          ])
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }